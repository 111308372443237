import "./styles.css";

import { Navigate, Route, Routes } from "react-router-dom";

import { URLs } from "./const";
import { useInitRemotes } from "./hooks/useRemotes";
import { useInitUser } from "./hooks/useUser";
import { Contact } from "./pages/Contact";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { NotFound } from "./pages/NotFound";
import { Privacy } from "./pages/Privacy";
import { Profile } from "./pages/Profile";
import { SignUp } from "./pages/SignUp";
import { TOS } from "./pages/TOS";

function Root() {
  useInitUser();
  useInitRemotes();

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path={URLs.login} element={<Login />} />
      <Route path={URLs.signup} element={<SignUp />} />
      <Route path={URLs.privacy} element={<Privacy />} />
      <Route path={URLs.tos} element={<TOS />} />
      <Route path={URLs.contact} element={<Contact />} />
      <Route
        path={`${URLs.profile}/*`}
        element={<Profile />}
        errorElement={<Navigate to={URLs.login} />}
      />
      <Route path={URLs.home} element={<Home />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Root;

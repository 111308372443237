import { Box, Button, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { Navigate, NavLink, Route, Routes, useMatch } from "react-router-dom";

import { helloWorld } from "../../api";
import { LoggedInPage } from "../../components/LoggedInPage";
import { theme, URLs } from "../../const";
import { useLoggedInRoute } from "../../hooks/useLoggedInRoute";
import { useUser } from "../../hooks/useUser";
import { list } from "./styles.module.scss";
import { Account } from "./subpages/Account";
import { Layouts } from "./subpages/Layouts";
import { Remotes } from "./subpages/Remotes";
import { Welcome } from "./subpages/Welcome";

const colorScheme = (isActive: boolean) => (isActive ? theme.main : undefined);

export function Profile() {
  useLoggedInRoute();
  const user = useUser();
  const isTopProfileRoute = Boolean(useMatch(URLs.profile));

  useEffect(() => {
    if (user) {
      helloWorld().then((result) => {
        const data = result.data;
        console.log("RESULT", data);
      });
    }
  }, []);

  return (
    <LoggedInPage flexDirection="row">
      {(user) => (
        <>
          <VStack m={5} textAlign="left" className={list}>
            <NavLink to={URLs.profile}>
              {() => (
                <Button colorScheme={colorScheme(isTopProfileRoute)}>
                  Profile
                </Button>
              )}
            </NavLink>
            <NavLink to="remotes">
              {({ isActive }) => (
                <Button colorScheme={colorScheme(isActive)}>Remotes</Button>
              )}
            </NavLink>
            <NavLink to="layouts">
              {({ isActive }) => (
                <Button colorScheme={colorScheme(isActive)}>Layouts</Button>
              )}
            </NavLink>
            <NavLink to="account">
              {({ isActive }) => (
                <Button colorScheme={colorScheme(isActive)}>Account</Button>
              )}
            </NavLink>
          </VStack>
          <Box p={5}>
            <Routes>
              <Route index element={<Welcome />} />
              <Route path="remotes/*" element={<Remotes user={user} />} />
              <Route path="layouts/*" element={<Layouts user={user} />} />
              <Route path="account" element={<Account user={user} />} />
              <Route
                path="*"
                element={<Navigate to={URLs.profile} replace={true} />}
              />
            </Routes>
          </Box>
        </>
      )}
    </LoggedInPage>
  );
}

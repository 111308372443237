import { ChakraProvider } from "@chakra-ui/react";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";

import Root from "./Root";

if (module.hot) {
  module.hot.accept();
}

const rootDOM = document.getElementById("root");

if (!rootDOM) {
  throw new Error("anchor root DOM element not found, can't attach");
}

const rootElement = ReactDOM.createRoot(rootDOM);
rootElement.render(
  <ChakraProvider>
    <StrictMode>
      <RecoilRoot>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </RecoilRoot>
    </StrictMode>
  </ChakraProvider>
);

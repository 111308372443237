const MIN_PASSWORD_LENGTH = 8;

export function isValidEmail(
  potentialEmail: string | null = ""
): [boolean, Error[]] {
  const errors: Error[] = [];
  const isValid = /^\S+@\S+\.\S+$/.test(potentialEmail || "");

  if (!isValid) {
    errors.push(new Error("Incorrect e-mail syntax"));
  }

  return [isValid, errors];
}

export function isValidPassword(
  potentialPassword: string | null = ""
): [boolean, Error[]] {
  const errors = [];
  const { length } = potentialPassword || "";
  const hasMinLength = length >= MIN_PASSWORD_LENGTH;

  if (!hasMinLength) {
    errors.push(
      new Error(`Password must be at least ${MIN_PASSWORD_LENGTH} characters`)
    );
  }

  const isValid = hasMinLength;

  return [isValid, errors];
}

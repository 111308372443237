import { useEffect } from "react";
import { useNavigate } from "react-router";

import { URLs } from "../const";
import { ValueOf } from "../types";
import { useUser } from "./useUser";

export function useLoggedInRoute(to: ValueOf<typeof URLs> = URLs.login) {
  const isUserLoggedIn = !!useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserLoggedIn) {
      navigate(to);
    }
  }, [isUserLoggedIn, to]);
}

export function useLoggedOutRoute(to: ValueOf<typeof URLs> = URLs.profile) {
  const isUserLoggedIn = !!useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate(to);
    }
  }, [isUserLoggedIn, to]);
}

import { User } from "firebase/auth";
import React from "react";
import { Navigate } from "react-router-dom";

import { URLs } from "../../const";
import { useUser } from "../../hooks/useUser";
import { Page } from "../Page";

type Props = {
  children: (user: User) => React.ReactNode;
} & Omit<React.ComponentProps<typeof Page>, "children">;

export function LoggedInPage({ children, ...rest }: Props) {
  const user = useUser();

  return user ? (
    <Page {...rest}>{children(user)}</Page>
  ) : (
    <Navigate to={URLs.login} />
  );
}

import { User } from "firebase/auth";
import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";

import { auth } from "../api/firebase";

const userState = atom<User | null>({
  key: "user",
  default: null,
});

export function useUser() {
  return useRecoilState(userState)[0];
}

export function useInitUser() {
  const [, setUser] = useRecoilState(userState);

  useEffect(() => {
    auth.onAuthStateChanged(async (newUser) => {
      if (newUser) {
        console.log("logged in", newUser);
      } else {
        console.log("not logged in");
      }
      setUser(JSON.parse(JSON.stringify(newUser)));
    });
  }, []);
}

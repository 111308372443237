import { LockIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  chakra,
  Stack,
} from "@chakra-ui/react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useCallback, useState } from "react";

import { auth } from "../../api/firebase";
import { isValidEmail, isValidPassword } from "../../api/validation";
import { A } from "../../components/A";
import { AccountFlowShell } from "../../components/AccountFlowShell";
import { ComplexInputField } from "../../components/ComplexInputField";
import { DEFAULT_SIGN_UP_ERROR, errorMessages, theme, URLs } from "../../const";
import { useLoggedOutRoute } from "../../hooks/useLoggedInRoute";

const CViewIcon = chakra(ViewIcon);
const CLockIcon = chakra(LockIcon);

export function SignUp() {
  useLoggedOutRoute();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(process.env.DEBUG_EMAIL || "");
  const [password, setPassword] = useState(process.env.DEBUG_PASSWORD || "");
  const [error, setError] = useState("");

  const [validEmail] = isValidEmail(email);
  const [validPassword] = isValidPassword(password);

  const onCreateAccount = useCallback(() => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => setError(""))
      .catch((e) => {
        console.error(e.code);
        setError(errorMessages[e.code] || DEFAULT_SIGN_UP_ERROR);
      });
  }, [auth, email, password]);

  return (
    <AccountFlowShell
      email={email}
      outterContent={
        <>
          Already have an account? <A to={URLs.login}>Log in instead</A>
        </>
      }
    >
      <form>
        <Stack
          spacing={4}
          p="1rem"
          backgroundColor="whiteAlpha.900"
          boxShadow="md"
        >
          <ComplexInputField
            errorText="e-mail is required."
            helperText="We'll never share your email."
            icon={CViewIcon}
            isValid={validEmail}
            onChange={(newEmail) => {
              setEmail(newEmail);
              setError("");
            }}
            onSubmit={onCreateAccount}
            placeholder="e-mail address"
            type="email"
            value={email}
          />
          <ComplexInputField
            errorText="password is required."
            helperText="Use at least 8 characters."
            icon={CLockIcon}
            isValid={validPassword}
            onChange={(newPassword) => {
              setPassword(newPassword);
              setError("");
            }}
            onSubmit={onCreateAccount}
            placeholder="password"
            type={showPassword ? "text" : "password"}
            value={password}
            inputRightContents={
              <Button
                h="1.75rem"
                size="sm"
                onClick={() => setShowPassword((isSet) => !isSet)}
              >
                {showPassword ? "Hide" : "Show"}
              </Button>
            }
          />
          <Button
            borderRadius={0}
            variant="solid"
            colorScheme={theme.main}
            width="full"
            isDisabled={!password || !email}
            onClick={onCreateAccount}
          >
            Sign Up
          </Button>
        </Stack>
      </form>
      {error ? (
        <Alert status="error">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : null}
    </AccountFlowShell>
  );
}

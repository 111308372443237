import { AddIcon } from "@chakra-ui/icons";
import { Button, Heading, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { watchLayouts } from "../../../../api";
import { theme } from "../../../../const";
import { Layout } from "../../../../types";
import { SubPageProps } from "../../types";
import { LayoutItem } from "./LayoutItem";

type Props = SubPageProps;

// TODO layout sub navigation implementation pending

export function Layouts({ user }: Props) {
  const [layouts, setLayouts] = useState<Layout[]>([]);

  useEffect(() => watchLayouts(user.uid, setLayouts), [user.uid]);

  console.log("LA", layouts);

  return (
    <>
      <Heading as="h2" mb={5}>
        My Layouts
      </Heading>
      <Stack direction="row">
        {layouts.map((layout) => (
          <LayoutItem key={layout.id} layout={layout} />
        ))}
        <Button
          p={2}
          maxW="sm"
          mb={5}
          mr={5}
          colorScheme={theme.main}
          color={theme.mainContrast}
          minWidth={100}
          height="106"
        >
          <AddIcon />
        </Button>
      </Stack>
    </>
  );
}

import { User } from "firebase/auth";
import { useEffect, useMemo } from "react";
import { atom, useRecoilState } from "recoil";

// import { getRemoteDocs } from "../api";
import { auth } from "../api/firebase";
import { useUser } from "./useUser";

const userState = atom<User | null>({
  key: "user",
  default: null,
});

function deriveDisplayName(user: User | null): string {
  return user ? user.displayName || user.email?.split("@")[0] || "" : "";
}

export function useDisplayName(
  userProvidedUser?: User | null
): [string, (newDisplayName: string) => Promise<void>] {
  const user = useUser();
  const target = userProvidedUser || user;
  const displayName = deriveDisplayName(target);

  return useMemo(
    () => [
      displayName,
      async (newDisplayName) => {
        /* TODO: implement! */
        console.warn(
          "unimplemented, should save display name:",
          newDisplayName
        );
      },
    ],
    [displayName]
  );
}

export function useInitUser() {
  const [, setUser] = useRecoilState(userState);

  useEffect(() => {
    auth.onAuthStateChanged(async (newUser) => {
      if (newUser) {
        console.log("logged in", newUser);
        /* const remotes = await getRemoteDocs(newUser.uid);

        console.log(
          "remotes",
          remotes.docs.map((doc) => doc.data())
        );*/
      } else {
        console.log("not logged in");
      }
      setUser(JSON.parse(JSON.stringify(newUser)));
    });
  }, []);
}

import { Box } from "@chakra-ui/react";

import Footer from "../Footer";
import Header from "../Header";

type Props = {
  children: React.ReactNode;
  flexDirection?: "column" | "row";
};

export function Page({ children, flexDirection = "row" }: Props) {
  return (
    <Box h="100%" display="flex" flexDirection="column">
      <Header />
      <Box display="flex" flexGrow="1" flexDirection={flexDirection}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
}

// Values read from .env file
// In turn, these were pulled from
// https://support.google.com/firebase/answer/7015592?authuser=0#web&zippy=%2Cin-this-article
const appName = "cumoku";

export const DOMAIN = `https://${appName}.com`;
export const FN_EMULATOR_PORT = 5001;
export const DEV_HOST = "localhost";
export const AUTH_DOMAIN = `${appName}.firebaseapp.com`;
export const PROJECT_ID = appName;
export const STORAGE_BUCKET = `${appName}.appspot.com`;
export const URLs = {
  contact: "/contact",
  home: "/",
  login: "/login",
  privacy: "/privacy-policy",
  profile: "/profile",
  signup: "/sign-up",
  tos: "/terms-of-service",
};

export const theme = {
  danger: "red",
  main: "purple",
  mainContrast: "white",
  main400: "purple.400",
};

export const API_KEY = process.env.API_KEY || "";
export const APP_ID = process.env.APP_ID || "";
export const DEBUG_TOKEN = process.env.DEBUG_TOKEN || "";
export const MEASUREMENT_ID = process.env.MEASUREMENT_ID || "";
export const MESSAGING_SENDER_ID = process.env.MESSAGING_SENDER_ID || "";
export const RECAPTCHA_KEY = "6LectEcpAAAAAAHJPB3fvNk3FYWP_0XBxKrHISuG";

export const errorMessages: { [key: string]: string } = {
  "auth/user-not-found": "Wrong username or password",
  "auth/wrong-password": "Wrong username or password",
  "auth/weak-password": "Password must be at least 8 characters",
};
export const DEFAULT_LOGIN_ERROR = "Unable to log in, please try again later";
export const DEFAULT_SIGN_UP_ERROR =
  "Unable to sign you up, please try again later";

if (process.env.NODE_ENV === "development") {
  const required = {
    APP_ID,
    MEASUREMENT_ID,
    MESSAGING_SENDER_ID,
    RECAPTCHA_KEY,
  };

  if (Object.values(required).some((val) => !val)) {
    throw new Error(`Make sure required environment variables are defined, missing:
  - ${Object.entries(required)
    .filter(([, value]) => value)
    .map(([key]) => key)
    .join("\n  - ")}
`);
  }
}

import { Box, Text } from "@chakra-ui/react";

import { Page } from "../components/Page";

export function Contact() {
  return (
    <Page>
      <Box p="5" className="text-center">
        <Text align="center">Contact Us - WIP</Text>
      </Box>
    </Page>
  );
}

import { LockIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  chakra,
  FormHelperText,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useCallback, useState } from "react";

import { auth } from "../../api/firebase";
import { isValidEmail, isValidPassword } from "../../api/validation";
import { A } from "../../components/A";
import { AccountFlowShell } from "../../components/AccountFlowShell";
import { ComplexInputField } from "../../components/ComplexInputField";
import { ResetEmailDialog } from "../../components/ResetEmailDialog";
import { DEFAULT_LOGIN_ERROR, errorMessages, theme, URLs } from "../../const";
import { useLoggedOutRoute } from "../../hooks/useLoggedInRoute";

const CViewIcon = chakra(ViewIcon);
const CLockIcon = chakra(LockIcon);

export function Login() {
  useLoggedOutRoute();
  const modalProps = useDisclosure();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(process.env.DEBUG_EMAIL || "");
  const [password, setPassword] = useState(process.env.DEBUG_PASSWORD || "");
  const [error, setError] = useState("");

  const [validEmail] = isValidEmail(email);
  const [validPassword] = isValidPassword(password);

  const isSubmitDisabled = !validEmail || !validPassword;

  const onLogin = useCallback(() => {
    if (!isSubmitDisabled) {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => setError(""))
        .catch((e) => {
          console.error(e.code);
          setError(errorMessages[e.code] || DEFAULT_LOGIN_ERROR);
        });
    }
  }, [auth, email, password]);

  return (
    <AccountFlowShell
      email={email}
      outterContent={
        <>
          New around here? <A to={URLs.signup}>Sign Up</A>
        </>
      }
    >
      <form>
        <Stack
          spacing={4}
          p="1rem"
          backgroundColor="whiteAlpha.900"
          boxShadow="md"
        >
          <ComplexInputField
            errorText="A valid e-mail is required."
            icon={CViewIcon}
            isValid={validEmail}
            onChange={(newEmail) => {
              setEmail(newEmail);
              setError("");
            }}
            onSubmit={onLogin}
            placeholder="e-mail address"
            type="email"
            value={email}
          />
          <ComplexInputField
            errorText="A password is required."
            icon={CLockIcon}
            isValid={validPassword}
            onChange={(newPassword) => {
              setPassword(newPassword);
              setError("");
            }}
            onSubmit={onLogin}
            placeholder="password"
            type={showPassword ? "text" : "password"}
            value={password}
            inputRightContents={
              <Button
                h="1.75rem"
                size="sm"
                onClick={() => setShowPassword((isSet) => !isSet)}
              >
                {showPassword ? "Hide" : "Show"}
              </Button>
            }
          >
            <FormHelperText textAlign="right">
              <Button variant="link" onClick={() => modalProps.onOpen()}>
                forgot password?
              </Button>
            </FormHelperText>
          </ComplexInputField>
          <Button
            borderRadius={0}
            type="button"
            variant="solid"
            colorScheme={theme.main}
            width="full"
            isDisabled={isSubmitDisabled}
            onClick={onLogin}
          >
            Login
          </Button>
        </Stack>
      </form>
      {error ? (
        <Alert status="error">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : null}
      <ResetEmailDialog {...modalProps} />
    </AccountFlowShell>
  );
}

import { useGLTF } from "@react-three/drei";
import { useRef } from "react";
import { BufferGeometry, NormalBufferAttributes } from "three";

import modelPath from "./Poimandres.gltf";

export function Model() {
  const groupRef = useRef(null);
  const { nodes, materials } = useGLTF<"./Poimandres.gltf">(modelPath);
  return (
    <group ref={groupRef} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          (
            nodes.Curve007_1 as unknown as {
              geometry: BufferGeometry<NormalBufferAttributes>;
            }
          ).geometry
        }
        material={materials["Material.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          (
            nodes.Curve007_2 as unknown as {
              geometry: BufferGeometry<NormalBufferAttributes>;
            }
          ).geometry
        }
        material={materials["Material.002"]}
      />
    </group>
  );
}

useGLTF.preload(modelPath);

import { CheckIcon, SpinnerIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Card,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { UserAvatar } from "../../../../components/UserAvatar";
import { theme } from "../../../../const";
import { useDisplayName } from "../../../../hooks/useDisplayName";
import { SubPageProps } from "../../types";
import { DeleteAccountButton } from "./DeleteAccountButton";
import { LastSignIn } from "./LastSignIn";

type Props = SubPageProps;

export function Account({ user }: Props) {
  const [displayName, setDisplayName] = useDisplayName(user);
  const [draftName, setDraftDisplayName] = useState(displayName);
  const [updating, setUpdating] = useState(false);
  const [errorText, setErrorText] = useState("");
  const IconComponent = updating
    ? SpinnerIcon
    : errorText
    ? WarningIcon
    : CheckIcon;

  useEffect(() => {
    setDraftDisplayName(displayName);
  }, [displayName]);

  return (
    <>
      <Box mt={5} mb={5}>
        <FormControl mb={5}>
          <FormLabel>Profile Picture</FormLabel>
          <Link target="_blank" href="http://en.gravatar.com/emails">
            <Stack align="center" direction="row">
              <UserAvatar email={user.email} size="lg" />
              <Text>Edit (Gravatar)</Text>
            </Stack>
          </Link>
        </FormControl>
        <FormControl mb={5}>
          <FormLabel>Display Name</FormLabel>
          <InputGroup>
            <Input
              type="text"
              placeholder="for example, John"
              value={draftName}
              onChange={({ target }) => {
                setDraftDisplayName(target.value);
                setErrorText("");
              }}
              onBlur={() => {
                if (draftName !== displayName && !!draftName) {
                  setErrorText("");
                  setUpdating(true);
                  setDisplayName(draftName)
                    .catch((e) => setErrorText(e.message))
                    .finally(() => setUpdating(false));
                }
              }}
            />
            <InputRightAddon>
              <IconComponent />
            </InputRightAddon>
          </InputGroup>
          <FormHelperText>What should we call you?</FormHelperText>
          {errorText?.length ? (
            <FormErrorMessage>{errorText}</FormErrorMessage>
          ) : null}
        </FormControl>
        <Card mt={10} p={4} colorScheme={theme.main} variant="filled">
          <Heading as="h3" mb={5} size="sm">
            Danger Zone
          </Heading>
          <Text size="sm" mb={5}>
            Deleting your account will result in immediate access and data loss
          </Text>
          <DeleteAccountButton />
        </Card>
      </Box>
      <LastSignIn />
    </>
  );
}

import { Box, Image } from "@chakra-ui/react";

import { Page } from "../components/Page";
import { ThreeDRender } from "../components/ThreeDRender";
import logoSrc from "./logo.png";

export function Home() {
  return (
    <Page>
      <Box p="5" w="50%" ml={50}>
        <Image src={logoSrc} maxH={500} />
        <Box m={5}>The customizable remote, compatible with Roku</Box>
      </Box>
      <ThreeDRender w="50%" />
    </Page>
  );
}

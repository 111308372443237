import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router";

import { Page } from "../../components/Page";
import { theme, URLs } from "../../const";
import { useUser } from "../../hooks/useUser";
import { UserAvatar } from "../UserAvatar";

type Props = {
  children: React.ReactNode;
  email: string;
  outterContent: React.ReactNode;
};

export function AccountFlowShell({ children, outterContent, email }: Props) {
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    if (user) {
      navigate(URLs.profile);
    }
  }, [!!user]);

  return (
    <Page flexDirection="column">
      <Box height="100%">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Stack
            flexDir="column"
            m="2"
            justifyContent="center"
            alignItems="center"
          >
            <UserAvatar email={email} />
            <Heading color={theme.main400}>Welcome</Heading>
            <Box minW={{ base: "90%", md: "468px" }}>{children}</Box>
          </Stack>
          {outterContent ? <Box>{outterContent}</Box> : null}
        </Flex>
      </Box>
    </Page>
  );
}

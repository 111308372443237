import { EditIcon } from "@chakra-ui/icons";
import {
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
} from "@chakra-ui/react";

import { theme } from "../../../../const";
import { Layout } from "../../../../types";
import thumbnail from "./alpha_remote_thumbnail.jpg";

type Props = {
  layout: Layout;
};

export function LayoutItem({ layout }: Props) {
  return (
    <Card p={2} maxW="sm" mb={5} mr={5}>
      <Stack direction="row">
        <CardBody>
          <Image
            src={thumbnail}
            alt="layout"
            borderRadius="lg"
            maxHeight={50}
          />
        </CardBody>
        <CardFooter>
          <Heading colorScheme={theme.main} size="md" mr={2}>
            {layout.id}
          </Heading>
        </CardFooter>
        <EditIcon m={1} />
      </Stack>
    </Card>
  );
}

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";

import { auth } from "../../api/firebase";

type Props = ReturnType<typeof useDisclosure>;

export function ResetEmailDialog({ isOpen, onClose }: Props) {
  const [email, setEmail] = useState("");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reset Password</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            type="email"
            value={email}
            placeholder="Enter e-mail"
            onChange={({ target }) => setEmail(target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              sendPasswordResetEmail(auth, email)
                .then(() => {
                  setEmail("");
                })
                .catch(() => {
                  alert(
                    "Failed to send password reset e-mail, please try again later or contact support."
                  );
                })
                .finally(() => {
                  onClose();
                });
            }}
          >
            Send Reset e-mail
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

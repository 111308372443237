import { Navigate, Route, Routes } from "react-router";

import { URLs } from "../../../../const";
import { SubPageProps } from "../../types";
import { Edit } from "./subpages/Edit";
import { ListRemotes } from "./subpages/List";

type Props = SubPageProps;

export function Remotes({ user }: Props) {
  return (
    <Routes>
      <Route index element={<ListRemotes />} />
      <Route path="new" element={<Edit user={user} />} />
      <Route path="edit/:id" element={<Edit user={user} />} />
      <Route
        path="*"
        element={<Navigate to={`${URLs.profile}/remotes`} replace={true} />}
      />
    </Routes>
  );
}

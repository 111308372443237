import { Box } from "@chakra-ui/react";

import { useUser } from "../../../../hooks/useUser";

export function LastSignIn() {
  const user = useUser();

  if (!user?.metadata?.lastSignInTime) {
    return null;
  }

  return (
    <Box>
      Last login on{" "}
      {new Date(user.metadata.lastSignInTime).toLocaleDateString()}
    </Box>
  );
}

import { Box, Text } from "@chakra-ui/react";

import { theme, URLs } from "../../const";
import { A } from "../A";

const Footer = () => {
  return (
    <Box bg="gray.50" color="gray.700" pl={2} pr={2}>
      <Text color={theme.main} as="span">
        2024 CuMoKu Inc. all rights reserved
      </Text>
      <A ml={5} to={URLs.tos}>
        Terms of Service
      </A>
      <A ml={5} to={URLs.privacy}>
        Privacy Policy
      </A>
      <A ml={5} to={URLs.contact}>
        Contact Us
      </A>
    </Box>
  );
};

export default Footer;

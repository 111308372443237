import { Avatar } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { isValidEmail } from "../api/validation";
import { theme, URLs } from "../const";

function hashValue(val?: string | null) {
  return crypto.subtle
    .digest(
      "SHA-256",
      new TextEncoder().encode((val || "").trim().toLowerCase())
    )
    .then((h) => {
      const hexes = [];
      const view = new DataView(h);
      for (let i = 0; i < view.byteLength; i += 4) {
        hexes.push(("00000000" + view.getUint32(i).toString(16)).slice(-8));
      }
      return hexes.join("");
    });
}

type Props = {
  email?: string | null;
  size?: string;
};

const GRAVATAR_URL = "https://gravatar.com/avatar";
const DEFAULT_AVATAR_SIZE = 48;
const sizes: { [key: string]: number } = {
  lg: 60,
};
/**
 * g: suitable for display on all websites with any audience type.
 * pg: may contain rude gestures, provocatively dressed individuals, the lesser swear words, or mild violence.
 * r: may contain such things as harsh profanity, intense violence, nudity, or hard drug use.
 * x: may contain hardcore sexual imagery or extremely disturbing violence.
 */
const RATING = "pg";
/**
 * 404: do not load any image if none is associated with the email hash, instead return an HTTP 404 (File Not Found) response
 * mp: (mystery-person) a simple, cartoon-style silhouetted outline of a person (does not vary by email hash)
 * identicon: a geometric pattern based on an email hash
 * monsterid: a generated ‘monster’ with different colors, faces, etc
 * wavatar: generated faces with differing features and backgrounds
 * retro: awesome generated, 8-bit arcade-style pixelated faces
 * robohash: a generated robot with different colors, faces, etc
 * blank: a transparent PNG image (border added to HTML below for demonstration purposes)
 */
const DEFAULT_IMAGE = "404";

export function UserAvatar({ email, size = "" }: Props) {
  const [hash, setHash] = useState("");
  const [isValid] = isValidEmail(email);

  useEffect(() => {
    if (isValid) {
      hashValue(email).then(setHash);
    } else {
      setHash("");
    }
  }, [email, isValid]);

  const avatarSize = sizes[size] || DEFAULT_AVATAR_SIZE;

  return (
    <Avatar
      colorScheme={theme.main}
      border="2px"
      borderColor={theme.mainContrast}
      as={Link}
      size={size}
      to={URLs.profile}
      name={
        email && isValid ? email.split("@")[0].replace(/\./g, " ") : undefined
      }
      src={
        hash
          ? `${GRAVATAR_URL}/${hash}?s=${avatarSize}&r=${RATING}&d=${DEFAULT_IMAGE}`
          : undefined
      }
    />
  );
}

import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";

import { watchRemotes } from "../api";
import { Remote } from "../types";
import { useUser } from "./useUser";

const remotesState = atom<Remote[]>({
  key: "remotes",
  default: [],
});

export function useRemotes() {
  return useRecoilState(remotesState)[0];
}

export function useInitRemotes() {
  const user = useUser();
  const [, setRemotes] = useRecoilState(remotesState);

  useEffect(() => {
    if (user?.uid) {
      return watchRemotes(user.uid, setRemotes);
    } else {
      setRemotes([]);
    }
    return () => {};
  }, [user?.uid]);
}

import { LockIcon } from "@chakra-ui/icons";
import {
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { useState } from "react";

const CLockIcon = chakra(LockIcon);

export function ComplexInputField({
  children,
  errorText,
  helperText,
  icon: Icon,
  inputRightContents,
  isRequired = true,
  isValid,
  onChange,
  onSubmit,
  placeholder,
  type,
  value,
}: {
  children?: React.ReactNode;
  errorText?: string;
  helperText?: string;
  icon: typeof CLockIcon;
  inputRightContents?: React.ReactNode;
  isRequired?: boolean;
  isValid: boolean;
  onChange: (newValue: string) => void;
  onSubmit: () => void;
  placeholder?: string;
  type: "text" | "password" | "email";
  value: string;
}) {
  const [touched, setTouched] = useState(false);

  return (
    <FormControl isInvalid={!isValid && touched} isRequired={isRequired}>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          color="gray.300"
          children={<Icon color="gray.300" />}
        />
        <Input
          type={type}
          placeholder={placeholder}
          value={value}
          onBlur={() => setTouched(true)}
          onChange={({ target }) => onChange(target.value)}
          onKeyUp={({ key, preventDefault }) => {
            if (key === "enter") {
              onSubmit();
              return preventDefault();
            }
          }}
        />
        <InputRightElement width="4.5rem">
          {inputRightContents}
        </InputRightElement>
      </InputGroup>
      {helperText?.length && <FormHelperText>{helperText}</FormHelperText>}
      {errorText?.length && !isValid && touched ? (
        <FormErrorMessage>{errorText}</FormErrorMessage>
      ) : null}
      {children}
    </FormControl>
  );
}

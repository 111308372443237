import { EditIcon } from "@chakra-ui/icons";
import {
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
} from "@chakra-ui/react";

import { A } from "../../../../../../components/A";
import { theme, URLs } from "../../../../../../const";
import { Remote } from "../../../../../../types";
import thumbnail from "../../alpha_remote_thumbnail.jpg";

type Props = {
  remote: Remote;
};

export function RemoteItem({ remote }: Props) {
  return (
    <A
      p={2}
      maxW="sm"
      mb={5}
      mr={5}
      to={`${URLs.profile}/remotes/edit/${remote.id}`}
    >
      <Card>
        <Stack direction="row">
          <CardBody>
            <Image
              src={thumbnail}
              alt="remote"
              borderRadius="lg"
              maxHeight={50}
            />
          </CardBody>
          <CardFooter>
            <Heading colorScheme={theme.main} size="md" mr={2}>
              {remote.name}
            </Heading>
          </CardFooter>
          <EditIcon m={1} />
        </Stack>
      </Card>
    </A>
  );
}

import { Box, LayoutProps, SpaceProps } from "@chakra-ui/react";
import { Environment, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";

import { Model } from "./Model";

type Props = SpaceProps & LayoutProps;

export function ThreeDRender({ ...spaceAndLayoutProps }: Props) {
  return (
    <Box {...spaceAndLayoutProps}>
      <Canvas>
        <Suspense fallback={null}>
          <Model />
          <OrbitControls />
          <Environment preset="dawn" background />
        </Suspense>
      </Canvas>
    </Box>
  );
}

import { Button } from "@chakra-ui/react";
import { signOut } from "firebase/auth";

import { deleteAccount } from "../../../../api";
import { auth } from "../../../../api/firebase";
import { theme } from "../../../../const";

export function DeleteAccountButton() {
  return (
    <Button
      colorScheme={theme.danger}
      onClick={() => {
        const doIt = window.confirm(
          "Are you sure you want to delete your account? This operation is not reversible."
        );

        if (doIt) {
          deleteAccount();
          signOut(auth);
        }
      }}
    >
      Delete account
    </Button>
  );
}

import { Box, Text } from "@chakra-ui/react";

import { Page } from "../components/Page";

export function NotFound() {
  return (
    <Page>
      <Box p="5" className="text-center">
        <Text align="center">404 - Page Not Found</Text>
      </Box>
    </Page>
  );
}

import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";

import { watchLayouts } from "../api";
import { Layout } from "../types";
import { useUser } from "./useUser";

const layoutsState = atom<Layout[]>({
  key: "layouts",
  default: [],
});

export function useLayouts() {
  return useRecoilState(layoutsState)[0];
}

export function useInitLayouts() {
  const user = useUser();
  const [, setLayouts] = useRecoilState(layoutsState);

  useEffect(() => {
    if (user?.uid) {
      return watchLayouts(user.uid, setLayouts);
    } else {
      setLayouts([]);
    }
    return () => {};
  }, [user?.uid]);
}

import { LayoutProps, Link as ChakraLink, SpaceProps } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { theme, URLs } from "../const";
import { ValueOf } from "../types";

type Props = {
  children: React.ReactNode;
  color?: ValueOf<typeof theme>;
  to: ValueOf<typeof URLs>;
} & SpaceProps &
  LayoutProps;

export function A({
  children,
  color = theme.main,
  to,
  ...spaceAndLayoutProps
}: Props) {
  return (
    <ChakraLink as={Link} colorScheme={color} to={to} {...spaceAndLayoutProps}>
      {children}
    </ChakraLink>
  );
}

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { addRemote, deleteRemote } from "../../../../../../api";
import { A } from "../../../../../../components/A";
import { theme, URLs } from "../../../../../../const";
import { useLayouts } from "../../../../../../hooks/useLayouts";
import { useRemotes } from "../../../../../../hooks/useRemotes";
import { SubPageProps } from "../../../../../../pages/Profile/types";
import thumbnail from "../../alpha_remote_thumbnail.jpg";
import { CustomInput } from "./Input";

type Props = SubPageProps;
const DEFAULT_REMOTE_NAME = "My Remote";

export function Edit({ user }: Props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const remotes = useRemotes();
  const layouts = useLayouts();
  const remote = remotes.find((r) => r.id === id);
  const [draftName, setDraftName] = useState(DEFAULT_REMOTE_NAME);
  const [errorText, setErrorText] = useState("");
  const [registrationCode, setRegistrationCode] = useState("");
  const [updating, setUpdating] = useState(false);
  const isDisabled = updating || !draftName;

  useEffect(() => {
    setDraftName(remote?.name || DEFAULT_REMOTE_NAME);
  }, [remote?.name]);

  return (
    <Stack direction="row">
      <Box width="50%" pr={5}>
        <Heading size="md" mb={5}>
          {remote ? `Edit ${remote.name}` : "Add new remote"}
        </Heading>
        <CustomInput
          label="Device Name"
          placeholder="My CuMoKu"
          value={draftName}
          onChange={(value) => {
            setDraftName(value);
            setErrorText("");
          }}
          helpText="Name to identify the device"
        />
        <CustomInput
          label="Registration code"
          placeholder="ABCD-1234"
          value={registrationCode}
          onChange={(value) => {
            setRegistrationCode(value);
            setErrorText("");
          }}
          helpText="Code provided by the device when in pairing mode"
        />
        <Box mb={5}>
          <FormControl mb={2}>
            <FormLabel>Layouts</FormLabel>
            <CheckboxGroup
              colorScheme={theme.main}
              defaultValue={layouts.map((l) => l.id)}
            >
              <Stack spacing={[1, 5]} direction={["column", "row"]}>
                {layouts.map((layout) => (
                  <Checkbox value={layout.id}>{layout.name}</Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
            <FormHelperText>
              Select the layouts you want available on your remote
            </FormHelperText>
          </FormControl>
          <A to={`${URLs.profile}/layouts/new`}>New Layout</A>
        </Box>
        {remote ? (
          <Button
            mr={2}
            colorScheme={theme.danger}
            onClick={() => deleteRemote(user.uid, remote.id)}
          >
            Delete
          </Button>
        ) : null}
        {remotes.length ? (
          <Button mr={2} onClick={() => navigate(`${URLs.profile}/remotes`)}>
            Cancel
          </Button>
        ) : null}
        <Button
          colorScheme={theme.main}
          disabled={isDisabled}
          onClick={() => {
            if (!updating && draftName) {
              const hasOtherRemotesWithSameName = remotes
                .filter((r) => r.name === draftName)
                .some((r) => !remote || r.id !== remote.id);
              if (hasOtherRemotesWithSameName) {
                setErrorText(
                  "A remote with the same name already exists, please choose a different name"
                );
              } else {
                setUpdating(true);
                addRemote(user.uid, { name: draftName })
                  .then(() => setErrorText(""))
                  .then(() => navigate(`${URLs.profile}/remotes`))
                  .catch((e) => setErrorText(e.message))
                  .finally(() => setUpdating(false));
              }
            }
          }}
        >
          Save
        </Button>
        {errorText ? (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Failed to save changes</AlertTitle>
            <AlertDescription>{errorText}</AlertDescription>
          </Alert>
        ) : null}
      </Box>

      <Image maxWidth="50%" src={thumbnail} alt="remote" />
    </Stack>
  );
}

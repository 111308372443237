import { AddIcon } from "@chakra-ui/icons";
import { Heading, Stack } from "@chakra-ui/react";
import { Navigate } from "react-router";

import { A } from "../../../../../../components/A";
import { theme, URLs } from "../../../../../../const";
import { useRemotes } from "../../../../../../hooks/useRemotes";
import { RemoteItem } from "./RemoteItem";

export function ListRemotes() {
  const remotes = useRemotes();

  if (!remotes.length) {
    return <Navigate to="new" />;
  }

  return (
    <>
      <Heading as="h2" mb={5}>
        My Remotes
      </Heading>
      <Stack direction="row">
        {remotes.map((remote) => (
          <RemoteItem key={remote.id} remote={remote} />
        ))}
        <A
          p={2}
          to={`${URLs.profile}/remotes/new`}
          mb={5}
          mr={5}
          color={theme.mainContrast}
          minWidth={100}
          height="106"
        >
          <AddIcon />
        </A>
      </Stack>
    </>
  );
}

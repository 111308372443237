import { FirebaseError } from "firebase/app";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  FirestoreError,
  getDoc,
  getDocs,
  onSnapshot,
  Query,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { v4 as uuid } from "uuid";

import { Layout, Remote } from "../types";
import { db, functions } from "./firebase";

export const helloWorld = httpsCallable(functions, "helloWorld");
export const deleteAccount = httpsCallable(functions, "deleteUserAccount");

const userRef = collection(db, "users");

export const getUserDoc = (userId: string) => doc(userRef, userId);

const REMOTE_MODEL = "α";

const isRemote = (data: Partial<Remote>) => data.model === REMOTE_MODEL;

export const watchCollection = <A extends object>(
  query: Query,
  callback: (documents: A[]) => void,
  onError?: (error: FirestoreError) => void
) => {
  const unsubscribe = onSnapshot(
    query,
    (querySnapshot) => {
      const documents: A[] = [];
      querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() } as A);
      });
      callback(documents);
    },
    onError
  );

  return unsubscribe;
};

export const addRemote = async (
  userId: string,
  remote: Omit<Remote, "id" | "userId" | "model" | "version">
) => {
  try {
    await addDoc(collection(db, `users/${userId}/devices`), {
      ...remote,
      userId,
      model: REMOTE_MODEL,
      version: "1.0.0",
      id: uuid(),
    });
  } catch (error) {
    console.error("Error creating physical remote:", error);
  }
};

export const deleteRemote = async (userId: string, remoteId: string) => {
  try {
    const remoteRef = doc(db, `users/${userId}/devices`, remoteId);
    await deleteDoc(remoteRef);
    console.log(`Remote with id ${remoteId} deleted successfully.`);
  } catch (error) {
    console.error("Error deleting remote:", error);
  }
};

export const getDevice = async (deviceId: string) => {
  const docRef = doc(db, "devices", deviceId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = await docSnap.data();

    if (isRemote(data)) {
      return data;
    }
    return null;
  }
  return null;
};
export const updateDevice = (
  userId: string,
  deviceId: string,
  updatedData: object
) => {
  const remoteRef = doc(db, `users/${userId}/devices/${deviceId}`);
  return updateDoc(remoteRef, updatedData);
};

export const deleteDevice = (userId: string, deviceId: string) => {
  const remoteRef = doc(db, `users/${userId}/devices/${deviceId}`);
  return deleteDoc(remoteRef);
};

export const associateDeviceWithLayout = (
  userId: string,
  deviceId: string,
  layoutId: string
) => {
  const deviceRef = doc(db, `users/${userId}/devices/${deviceId}`);
  return updateDoc(deviceRef, { layoutId });
};

export const getLayouts = async (userId: string) => {
  const layoutsQuery = collection(db, `users/${userId}/layouts`);
  const querySnapshot = await getDocs(layoutsQuery);

  return querySnapshot.docs
    .filter((doc) => doc.data().userId === userId)
    .map((doc) => ({ id: doc.id, ...doc.data() }));
};

export function watchRemotes(
  userId: string,
  callback: (remotes: Remote[]) => unknown,
  onError?: (error: FirebaseError) => unknown
) {
  const remoteQuery = query(
    collection(db, `users/${userId}/devices`),
    where("model", "==", REMOTE_MODEL)
  );
  return watchCollection<Remote>(remoteQuery, callback, onError);
}

export function watchLayouts(
  userId: string,
  callback: (layouts: Layout[]) => unknown,
  onError?: (error: FirebaseError) => unknown
) {
  const layoutQuery = query(
    collection(db, `users/${userId}/layouts`),
    where("model", "==", REMOTE_MODEL)
  );
  return watchCollection<Layout>(layoutQuery, callback, onError);
}

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react";

import { theme } from "../../../../../../const";

type Props = {
  label: string;
  value: string;
  helpText?: string;
  placeholder?: string;
  onChange: (newValue: string) => unknown;
};

export function CustomInput({
  label,
  value,
  helpText,
  placeholder,
  onChange,
}: Props) {
  return (
    <FormControl mb={5}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Input
          type="text"
          placeholder={placeholder}
          colorScheme={theme.main}
          value={value}
          onChange={({ target }) => onChange(target.value)}
        />
      </InputGroup>
      {helpText ? <FormHelperText>{helpText}</FormHelperText> : null}
    </FormControl>
  );
}

import { APP_ID, AUTH_DOMAIN, PROJECT_ID, STORAGE_BUCKET } from "../const";

export const config = {
  apiKey: process.env.API_KEY,
  authDomain: AUTH_DOMAIN,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: process.env.MEASUREMENT_ID,
};

import { Box, Button } from "@chakra-ui/react";
import { signOut } from "firebase/auth";
import { Link, useLocation } from "react-router-dom";

import { auth } from "../../api/firebase";
import { theme, URLs } from "../../const";
import { useUser } from "../../hooks/useUser";
import { A } from "../A";
import { UserAvatar } from "../UserAvatar";

const ROUTES_WITHOUT_LOGIN_PROMPT = [URLs.login, URLs.signup];

const Header = () => {
  const user = useUser();
  const { pathname } = useLocation();

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      backgroundColor="purple.500"
      fontSize="md"
      fontWeight="bold"
      color="white"
      p={2}
    >
      <Box>
        <Link to={URLs.home}>CuMoKu</Link>
      </Box>
      <Box
        display="flex"
        ml="auto"
        alignItems="center"
        justifyContent="center"
        h="100%"
      />
      <Box
        display="flex"
        ml="auto"
        alignItems="center"
        justifyContent="center"
        h="100%"
      >
        {user ? (
          <>
            <Button
              as={Link}
              variant="outline"
              size="xs"
              mr="2"
              colorScheme={theme.mainContrast}
              onClick={() => signOut(auth)}
            >
              Log Out
            </Button>
            <UserAvatar email={user.email} />
          </>
        ) : !ROUTES_WITHOUT_LOGIN_PROMPT.includes(pathname) ? (
          <A color={theme.mainContrast} to={URLs.login}>
            Log In / Sign Up
          </A>
        ) : null}
      </Box>
    </Box>
  );
};

export default Header;
